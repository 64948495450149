<script setup></script>

<template>
    <div class="px-6 lg:p-0 lg:flex gap-10 pt-4 lg:pt-20 items-start">

        <NuxtLink to="/" class="lg:hidden font-semibold cursor-pointer absolute top-4 left-4">
                <span class="bg-black/15 w-10 h-10 flex items-center justify-center rounded-2xl hover:bg-black hover:fill-white">
                    <IconsChevLeftIcon/>
                </span>
        </NuxtLink>

        <div class="flex-grow block lg:flex justify-end">
            <NuxtLink to="/" class="hidden lg:flex items-center gap-3  font-semibold cursor-pointer">
                <span class="bg-black/15 w-10 h-10 flex items-center justify-center rounded-2xl hover:bg-black hover:fill-white">
                    <IconsChevLeftIcon/>
                </span>
            </NuxtLink>
        </div>
        <div class="w-full pt-1" style="max-width: 1080px;">
            <slot/>
        </div>
        <div class="flex-grow hidden lg:block"></div>
    </div>
</template>